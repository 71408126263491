@import '../../styles/variables.scss';

.home-section {
	align-items: center;
	background-color: var(--background-primary);
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	padding: 2rem 1rem 1rem 1rem;
	transition: background-color 0.4s ease, color 0.4s ease;

	.home-container {
		align-items: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;

		.content-container {
			.home-content {
				width: 100%;

				.home-title {
					align-items: center;
					color: var(--primary-color);
					display: flex;
					flex-wrap: wrap;
					font-size: 3rem;
					justify-content: center;
					transition: all 0.3s ease;

					.waving-hand {
						animation-duration: 2.5s;
						animation-iteration-count: infinite;
						animation-name: wave-animation;
						display: inline-block;
						transform-origin: 70% 70%;

						@media (min-width: $breakpoint-tablet) {
							margin-left: 0.5rem;
						}
					}

					@keyframes wave-animation {
						0%,
						70%,
						100% {
							transform: rotate(0deg);
						}
						15%,
						40% {
							transform: rotate(14deg);
						}
						30% {
							transform: rotate(-8deg);
						}
						50% {
							transform: rotate(-4deg);
						}
						60% {
							transform: rotate(10deg);
						}
					}
				}

				.home-info {
					color: var(--primary-text-color);
					font-size: 1.1rem;
				}

				.name-highlight {
					color: var(--secondary-color-hover);
					cursor: pointer;
					transition: color 0.3s ease-in-out;

					&:hover {
						color: var(--secondary-color);
					}
				}

				.pronunciation-tooltip {
					position: absolute;
					background-color: var(--background-primary);
					border: 1px solid var(--primary-color);
					color: var(--primary-text-color);
					padding: 1rem;
					width: 240px;
					z-index: 10;
					border-radius: 15px;
					box-shadow: 0px 0px 10px var(--shadow-color);
					padding-right: 35px;

					@media (max-width: $breakpoint-desktop) {
						left: 50%;
						transform: translateX(-50%);
					}
					@media (min-width: $breakpoint-desktop) {
						width: 230px;
						margin: 20px 0;
						padding-right: 20px;
					}

					.close-icon {
						position: absolute;
						right: 10px;
						top: 10px;
						color: var(--primary-color);
						cursor: pointer;
						font-size: 24px;
						transition: color 0.3s ease-in-out;

						&:hover {
							color: var(--secondary-color);
						}

						@media (min-width: $breakpoint-desktop) {
							display: none;
						}
					}
				}

				.fade-enter {
					opacity: 0;
				}

				.fade-enter-active {
					opacity: 1;
					transition: opacity 300ms;
				}

				.fade-exit {
					opacity: 1;
				}

				.fade-exit-active {
					opacity: 0;
					transition: opacity 300ms;
				}
			}

			.icon-container {
				bottom: 0.5rem;
				display: flex;
				flex-direction: row;
				gap: 0.5rem;
				justify-content: center;
				margin: 20px 0;
				position: static;
				z-index: 2;

				.icon-link {
					align-items: center;
					background-color: transparent;
					border-radius: 50%;
					display: flex;
					height: 2.5rem;
					justify-content: center;
					margin-bottom: 0.5rem;
					padding: 0.25rem;
					text-decoration: none;
					transition: background-color 0.2s ease, transform 0.2s ease;
					width: 2.5rem;

					&:hover {
						background-color: var(--secondary-color-hover);
						transform: scale(1.1);
					}
				}

				.icon {
					width: 2rem;
					height: 2rem;
					background-color: var(--primary-color);
					-webkit-mask-size: contain;
					-webkit-mask-repeat: no-repeat;
					mask-size: contain;
					mask-repeat: no-repeat;
					mask-position: center;
				}

				.github-icon .icon {
					-webkit-mask-image: url('../../assets/icons/github.svg');
					mask-image: url('../../assets/icons/github.svg');
					-webkit-mask-size: 95%;
					mask-size: 95%;
					mask-position: center;
				}

				.linkedin-icon .icon {
					-webkit-mask-image: url('../../assets/icons/linkedin.svg');
					mask-image: url('../../assets/icons/linkedin.svg');
					-webkit-mask-size: 90%;
					mask-size: 90%;
					mask-position: center;
				}
			}

			.techstack-container {
				align-items: center;
				display: flex;
				flex-direction: column;

				.techstack-title {
					color: var(--primary-color);
					font-size: 1.5em;
				}

				.techstack-icons {
					display: flex;
					flex-wrap: wrap;
					justify-content: center;

					.techstack-icon-container {
						align-items: center;
						background-color: var(--background-color);
						border-radius: 50%;
						box-shadow: 0px 4px 10px var(--shadow-color);
						color: var(--primary-color);
						cursor: pointer;
						display: flex;
						flex-direction: column;
						height: 40px;
						justify-content: center;
						margin: 10px;
						margin-bottom: 20px;
						overflow: hidden;
						padding: 10px;
						position: relative;
						transition: transform 0.3s ease-in-out;
						width: 40px;

						&:hover {
							transform: scale(1.1);

							.techstack-icon-name {
								opacity: 1;
								transform: translateY(0);
							}
						}

						.techstack-icon {
							height: 40px;
							width: 40px;
						}

						.techstack-icon-name {
							align-items: center;
							background-color: var(--background-translucent);
							border-radius: 50%;
							bottom: 0;
							box-sizing: border-box;
							display: flex;
							font-size: 0.8em;
							font-weight: bold;
							height: 100%;
							justify-content: center;
							left: 0;
							opacity: 0;
							overflow: hidden;
							padding: 5px;
							position: absolute;
							text-transform: uppercase;
							transform: translateY(100%);
							transition: all 0.3s ease-in-out;
							white-space: pre-wrap;
							width: 100%;
						}
					}
				}
			}
		}

		.profile-picture {
			border: 5px solid var(--primary-color);
			border-radius: 50%;
			height: 18rem;
			object-fit: cover;
			order: -1;
			transition: all 0.3s ease;
			width: 18rem;
		}

		@media (min-width: $breakpoint-desktop) {
			align-items: center;
			display: flex;
			flex-direction: row;
			padding: 3rem;

			.content-container {
				align-items: flex-start;
				display: flex;
				flex-direction: column;
				margin-top: 2rem;
				text-align: left;
				width: 70%;

				.home-content {
					.home-title {
						justify-content: flex-start;
						margin-bottom: 0;
					}

					.home-info {
						text-align: left;
					}
				}

				.techstack-container {
					align-items: flex-start;
					text-align: left;

					.techstack-icons {
						gap: 1.1rem;
						justify-content: flex-start;

						.techstack-icon-container {
							margin: 0;
						}
					}
				}
			}

			.profile-picture {
				order: 0;
			}
		}

		@media (max-width: $breakpoint-mobile) {
			.home-title {
				font-size: 2.5rem !important;
				line-height: 1.2;
				margin-bottom: 0.5rem;
			}

			.profile-picture {
				height: 12rem;
				width: 12rem;
			}
		}
	}
}
