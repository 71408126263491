@import '../../styles/variables.scss';

.about-section {
	align-items: center;
	background-color: var(--background-color);
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: 100vh;
	padding: 2rem;
	transition: background-color 0.4s ease, color 0.4s ease;

	.about-container {
		align-items: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin: 0 auto;
		max-width: 1200px;
		text-align: center;

		.about-gif {
			border-radius: 50%;
			max-width: 300px;
			width: 100%;
		}

		.about-main {
			h1 {
				color: var(--primary-color);
				font-size: 2rem;
				margin: 1em 0;
			}

			h2 {
				color: var(--secondary-color);
				font-size: 1.5rem;
			}

			p {
				color: var(--primary-text-color);
				line-height: 1.5;
				margin-bottom: 2em;
			}
		}

		@media (min-width: $breakpoint-desktop) {
			align-items: center;
			flex-direction: row;
			gap: 4rem;
			justify-content: space-between;

			.about-gif {
				align-self: center;
				max-width: 400px;
				order: -1;
			}

			.about-main {
				max-width: 700px;
				text-align: left;
			}
		}
	}
}
