@import '../../styles/variables.scss';

.projects-section {
	align-items: center;
	background-color: var(--background-primary);
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	transition: background-color 0.4s ease, color 0.4s ease;

	.projects-container {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 3rem;
		max-width: 90%;
		margin: 0 auto;
		margin-bottom: 50px;
		padding: 2rem;

		@media (min-width: $breakpoint-mobile) {
			max-width: 550px;
		}

		@media (min-width: $breakpoint-desktop) {
			max-width: 1000px;
		}

		.section-title {
			color: var(--primary-color);
			font-size: 2rem;
			margin-bottom: 1rem;
			text-align: center;
		}

		.project-card {
			background-color: var(--background-color);
			border-radius: 10px;
			box-sizing: border-box;
			box-shadow: 0 4px 8px 0 var(--project-card-shadow-primary),
				0 6px 20px 0 var(--project-card-shadow-secondary);
			display: flex;
			flex-direction: column;
			width: 100%;
			transition: background-color 0.4s ease, color 0.4s ease;

			@media (min-width: $breakpoint-desktop) {
				flex-direction: row;

				.project-image {
					flex: 1;
					order: 2;
				}

				.project-details {
					align-items: center;
					display: flex;
					flex: 1;
					flex-direction: column;
					justify-content: center;
					order: 1;
				}

				&:nth-child(even) {
					.project-image {
						order: 1;
					}

					.project-details {
						order: 2;
					}
				}
			}

			.project-details {
				padding: 1rem;

				.project-title {
					color: var(--primary-color);
					font-size: 1.5rem;
					margin-bottom: 0;
					text-align: center;
				}

				.project-description {
					color: var(--primary-text-color);
					line-height: 1.5;
					margin-bottom: 2rem;
					text-align: center;
				}

				.project-languages {
					display: flex;
					flex-wrap: wrap;
					gap: 10px;
					margin-bottom: 2rem;
					justify-content: center;

					span {
						background-color: var(--background-color);
						border-radius: 10px;
						box-shadow: 0 0 10px var(--project-card-shadow-primary);
						color: var(--primary-text-color);
						font-weight: bold;
						padding: 5px 10px;
						transition: background-color 0.4s ease, color 0.4s ease;
					}
				}

				.project-links {
					display: flex;
					gap: 1rem;
					justify-content: center;

					a,
					span {
						align-items: center;
						color: var(--primary-color);
						display: flex;
						text-decoration: none;
						transition: color 0.3s ease-in-out;

						svg {
							margin-left: 5px;
							font-size: 1.1rem;
						}

						&:hover {
							color: var(--secondary-color);
						}
					}
				}
			}
		}
	}
}
