.theme-toggle-container svg {
	width: 1.6rem;
	height: 1.6rem;
}

[data-theme='dark'] .theme-toggle-container {
	color: var(--primary-color);
}

[data-theme='dark'] .theme-toggle-container button {
	color: var(--primary-color);
}

[data-theme='light'] .theme-toggle-container {
	color: var(--secondary-color);
}
