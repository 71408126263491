@import '../../styles/variables.scss';

.navbar-container {
	align-items: center;
	background: var(--background-color);
	box-shadow: 0px 1px 6px var(--shadow-color);
	display: flex;
	justify-content: space-between;
	padding: 1em;
	position: sticky;
	top: 0;
	transition: background-color 0.4s ease, color 0.4s ease;
	z-index: 100;
}

.navbar-name,
.navbar-links {
	align-items: center;
	display: flex;
	font-weight: 600;
}

.navbar-name-link {
	color: var(--primary-color);
	font-size: 1.5rem;
	text-decoration: none;
}

.navbar-links-container {
	align-items: center;
	display: flex;
	gap: 1.5rem;
}

.navbar-links {
	color: var(--primary-color);
	justify-content: space-between;
	max-width: 500px;
	gap: 2rem;
}

.theme-toggle {
	align-items: center;
	display: flex;
	justify-content: center;
}

.navbar-container a,
.mobile-menu a {
	color: var(--primary-color);
	cursor: pointer;
	transition: color 0.3s ease;
}

.navbar-container a:hover,
.mobile-menu a:hover,
.active {
	color: var(--secondary-color) !important;
}

.menu-button {
	background-color: transparent;
	border: none;
	color: var(--primary-color);
	cursor: pointer;
	display: none;
	transition: color 0.3s ease-in-out;
	margin-left: auto;

	&:hover {
		color: var(--secondary-color);
	}
}

.close-button {
	background: transparent;
	border: none;
	color: var(--primary-color);
	cursor: pointer;
	position: absolute;
	top: 1rem;
	right: 1rem;
	transition: color 0.3s ease-in-out;

	&:hover {
		color: var(--secondary-color);
	}
}

.mobile-menu {
	align-items: center;
	background: var(--background-color);
	display: flex;
	flex-direction: column;
	height: 100vh;
	justify-content: center;
	left: 0;
	position: fixed;
	top: 0;
	transform: translateY(-100%);
	transition: transform 0.3s ease-in-out, background-color 0.4s ease,
		color 0.4s ease;
	width: 100%;
}

.mobile-menu.open {
	transform: translateY(0);
}

.navbar-theme-toggle-mobile,
.mobile-menu a {
	font-size: 1.5rem;
	font-weight: 600;
	padding: 1em;
	text-align: center;
}

@media (max-width: $breakpoint-desktop) {
	.navbar-links {
		display: none;
	}

	.menu-button {
		display: block;
	}
}

@media (min-width: $breakpoint-desktop) {
	.theme-toggle {
		transition: color 0.3s ease-in-out;

		&:hover {
			color: var(--secondary-color) !important;
		}
	}
}
