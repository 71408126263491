@import '../../styles/variables.scss';

.project-image {
	background-color: var(--background-color);
	border-radius: 10px;
	box-sizing: border-box;
	height: 350px;
	overflow: hidden;
	padding: 1rem;
	position: relative;
	transition: background-color 0.4s ease, color 0.4s ease;
	width: 100%;

	a {
		border-radius: 10px;
		box-shadow: 0 2px 4px 0 var(--project-image-link-shadow-primary),
			0 3px 10px 0 var(--project-image-link-shadow-secondary);
		display: block;
		height: 100%;
		overflow: hidden;
		position: relative;
	}

	img {
		border-radius: 10px;
		height: auto;
		left: 0;
		object-fit: cover;
		object-position: top;
		position: absolute;
		top: 0;
		width: 100%;
	}
}
