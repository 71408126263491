@import '../../styles/variables.scss';

.experience-section {
	align-items: flex-start;
	background-color: var(--background-secondary);
	display: flex;
	justify-content: center;
	min-height: 100vh;
	padding: 2rem;
	transition: background-color 0.4s ease, color 0.4s ease;

	.experience-container {
		margin: 0 auto;
		max-width: 1200px;
		// padding: 2rem;
		width: 100%;

		.section-title {
			color: var(--primary-color);
			font-size: 2rem;
			margin-bottom: 1rem;
			text-align: center;
		}

		.experience-timeline {
			margin: 0 auto;
			max-width: 750px;
			position: relative;

			&:before {
				background: var(--secondary-color);
				bottom: 0;
				content: '';
				left: 70px;
				margin-left: -1px;
				position: absolute;
				top: 0;
				width: 2px;
			}

			.experience-timeline-item {
				align-items: center;
				display: flex;
				margin: 4rem 0;
				position: relative;

				.experience-year {
					color: var(--primary-color);
					position: absolute;
					text-align: left;
					top: -10px;
					z-index: 1;
				}

				.present {
					font-weight: bold;
				}

				.experience-circle {
					background: var(--secondary-color);
					border: 2px solid var(--primary-color);
					border-radius: 50%;
					height: 15px;
					left: 61px;
					margin-top: -7.5px;
					position: absolute;
					top: 0;
					width: 15px;
					z-index: 2;
				}

				.experience-content {
					margin-left: 90px;
					padding-left: 20px;
					position: relative;
					top: -10px;

					.experience-title {
						color: var(--primary-color);
						font-size: 1.25rem;
						font-weight: bold;
					}

					.experience-role {
						color: var(--primary-text-color);
						font-weight: bold;
						margin-top: -15px;
					}

					.experience-description {
						color: var(--primary-text-color);
						line-height: 1.5;
						margin-top: 0.5rem;
					}
				}

				@media (max-width: $breakpoint-tablet) {
					.experience-content {
						margin-left: 80px;

						.experience-title {
							font-size: 1.1rem;
						}

						.experience-role {
							font-size: 14px;
						}

						.experience-description {
							font-size: 14px;
						}
					}
				}
			}
		}
	}
}
