@import '../../styles/variables.scss';

.footer-container {
	background-color: var(--primary-color);
	color: var(--background-color);
	padding: 1rem;
	position: relative;
	text-align: center;
	transition: background-color 0.4s ease, color 0.4s ease;

	.footer-content {
		display: flex;
		justify-content: center;
		gap: 1rem;
		margin-bottom: 1rem;
		margin-top: 1rem;
	}

	.icon-link {
		color: var(--background-color);
		transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;

		&:hover {
			color: var(--secondary-color);
			transform: scale(1.2);
		}
	}

	.footer-text {
		font-size: 0.9rem;
	}

	@media (min-width: $breakpoint-desktop) {
		.footer-content {
			gap: 2rem;
		}

		.footer-text {
			font-size: 1rem;
		}
	}
}
