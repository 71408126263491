@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
@import './styles/variables';

:root {
	--background-color: #{$dark-background-color};
	--background-primary: #{$dark-background-primary};
	--background-secondary: #{$dark-background-secondary};
	--primary-color: #{$dark-primary-color};
	--primary-text-color: #{$dark-primary-text-color};
	--secondary-color: #{$dark-secondary-color};
	--secondary-color-hover: #{$dark-secondary-color-hover};
	--shadow-color: #{$dark-shadow-color};
	--background-translucent: #{$dark-background-translucent};
	--scrollbar-thumb-color: #{$dark-scrollbar-thumb-color};
	--scrollbar-thumb-hover-color: #{$dark-scrollbar-thumb-hover-color};
	--project-card-shadow-primary: #{$dark-project-card-shadow-primary};
	--project-card-shadow-secondary: #{$dark-project-card-shadow-secondary};
	--project-image-link-shadow-primary: #{$dark-project-image-link-shadow-primary};
	--project-image-link-shadow-secondary: #{$dark-project-image-link-shadow-secondary};
	--theme-toggle-shadow-primary: #{$dark-theme-toggle-shadow-primary};
	--theme-toggle-shadow-secondary: #{$dark-theme-toggle-shadow-secondary};
}

// Light theme CSS variables
[data-theme='light'] {
	--background-color: #{$light-background-color};
	--background-primary: #{$light-background-primary};
	--background-secondary: #{$light-background-secondary};
	--primary-color: #{$light-primary-color};
	--primary-text-color: #{$light-primary-text-color};
	--secondary-color: #{$light-secondary-color};
	--secondary-color-hover: #{$light-secondary-color-hover};
	--shadow-color: #{$light-shadow-color};
	--background-translucent: #{$light-background-translucent};
	--scrollbar-thumb-color: #{$light-scrollbar-thumb-color};
	--scrollbar-thumb-hover-color: #{$light-scrollbar-thumb-hover-color};
	--project-card-shadow-primary: #{$light-project-card-shadow-primary};
	--project-card-shadow-secondary: #{$light-project-card-shadow-secondary};
	--project-image-link-shadow-primary: #{$light-project-image-link-shadow-primary};
	--project-image-link-shadow-secondary: #{$light-project-image-link-shadow-secondary};
	--theme-toggle-shadow-primary: #{$light-theme-toggle-shadow-primary};
	--theme-toggle-shadow-secondary: #{$light-theme-toggle-shadow-secondary};
}

// Global styles using CSS variables
body {
	font-family: 'Poppins', sans-serif;
	margin: 0;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;

	/* Scrollbar Style */
	&::-webkit-scrollbar {
		width: 10px;
	}

	&::-webkit-scrollbar-track {
		background: var(--background-color);
	}

	&::-webkit-scrollbar-thumb {
		background: var(--scrollbar-thumb-color);
		border-radius: 20px;
	}

	&::-webkit-scrollbar-thumb:hover {
		background: var(--scrollbar-thumb-hover-color);
	}
}

::selection {
	background: var(--secondary-color);
	color: var(--background-color);
}

::-moz-selection {
	background: var(--secondary-color);
	color: var(--background-color);
}
