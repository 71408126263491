@import '../../styles/variables.scss';

.contact-section {
	align-items: center;
	background-color: var(--background-color);
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: 100vh;
	padding: 2rem;
	transition: background-color 0.4s ease, color 0.4s ease;

	.contact-container {
		align-items: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin: 0 auto;
		text-align: center;

		.section-title {
			color: var(--primary-color);
			font-size: 2rem;
			margin-bottom: 1rem;
			text-align: center;
		}

		.section-sub-title {
			color: var(--primary-color);
		}

		.contact-form {
			margin-bottom: 2rem;
			max-width: 400px;
			width: 100%;
		}

		.form-label {
			color: var(--primary-text-color);
			margin-bottom: -0.5rem;
		}

		.form-input {
			background-color: var(--background-primary);
			border: 1px solid var(--primary-text-color);
			border-radius: 10px;
			color: var(--primary-text-color);
			box-sizing: border-box;
			font-family: inherit;
			font-size: 14px;
			margin-bottom: 2rem;
			margin-top: 0.5rem;
			padding: 0.5rem;
			resize: none;
			transition: border-color 0.3s ease;
			width: 100%;

			&:focus {
				border-color: var(--secondary-color);
				outline: none;
			}
		}

		.form-button {
			background-color: var(--background-primary);
			border: 1px solid var(--primary-text-color);
			border-radius: 10px;
			color: var(--primary-text-color);
			cursor: pointer;
			font-size: 16px;
			font-family: inherit;
			padding: 0.5rem 1rem;
			transition: background-color 0.3s, color 0.3s;
			width: 40%;

			&:hover {
				background-color: var(--background-secondary);
				color: var(--primary-color);
			}

			@media (max-width: $breakpoint-tablet) {
				width: 100%;
			}
		}

		.contact-details {
			margin-top: 2rem;
			width: 100%;

			.contact-details-container {
				align-items: center;
				display: flex;
				flex-direction: column;
				width: 100%;

				.contact-icons {
					align-items: center;
					display: flex;
					flex-direction: column;
					justify-content: center;
					margin: 10px;
					margin-bottom: 20px;
					width: 100%;

					.contact-icon-container {
						align-items: center;
						animation: pulse 2s infinite;
						background-color: var(--background-color);
						border-radius: 50%;
						box-shadow: 0 2px 5px 0 var(--shadow-color);
						cursor: pointer;
						display: flex;
						flex-direction: column;
						height: 50px;
						justify-content: center;
						margin: 10px;
						overflow: hidden;
						padding: 10px;
						position: relative;
						width: 50px;

						.icon {
							color: var(--secondary-color-hover);
							height: 30px;
							width: 30px;
							transition: color 0.3s ease-in-out;
						}

						@media (min-width: $breakpoint-desktop) {
							&:hover {
								transform: scale(1.1);

								.icon {
									color: var(--secondary-color);
								}
							}
						}

						@keyframes pulse {
							0% {
								transform: scale(1);
							}
							50% {
								transform: scale(1.1);
							}
							100% {
								transform: scale(1);
							}
						}
					}

					.contact-text {
						h3,
						p {
							text-align: center;
							white-space: nowrap;
							width: 100%;
						}

						p {
							margin-top: -20px;
						}
					}
				}
			}

			h3 {
				color: var(--primary-color);
				font-size: 1.5rem;
			}

			p {
				color: var(--primary-text-color);
				margin-top: 0.5rem;
			}

			@media (min-width: $breakpoint-desktop) {
				.contact-details-container {
					flex-direction: row;
					gap: 10rem;
					justify-content: space-around;

					.contact-icons {
						flex-direction: row;
						justify-content: flex-start;

						.contact-icon-container {
							margin-right: 2rem;
						}

						.contact-text {
							h3,
							p,
							a {
								text-align: left;
							}
						}
					}
				}
			}
		}

		h2 {
			margin-bottom: 2rem;
		}
	}
}

.contact-popup {
	background-color: var(--background-primary);
	border-radius: 10px;
	box-shadow: 0px 4px 10px var(--shadow-color);
	color: var(--primary-text-color);
	padding: 1rem 2rem;
	position: fixed;
	right: 1rem;
	top: 6rem;
	transition: all 0.3s ease-out;
	z-index: 9999;

	@media (max-width: $breakpoint-tablet) {
		left: 50%;
		padding: 1rem 0.8rem;
		text-align: center;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 80%;
	}
}
